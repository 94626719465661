import { Input } from "antd";
import { Rule } from "antd/lib/form";
import {
  GoogleSmartSettingsSelector,
  HasFilledHeadlinesSelector,
  UpdateGoogleSmartSettings,
} from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { ReactNode } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxList from "tools/relaxForm/RelaxList";
import { RelaxValidationResults } from "tools/relaxForm/RelaxValidationResults";
import {
  ruleNoDuplicates,
  rulesetArrayMinMax,
  rulesetMinMax,
  ruleValidHeadline,
} from "tools/relaxForm/relaxPresets";


type TextListProps = {
  type: "headlines" | "short_descriptions" | "descriptions"
  listRules: Rule[],
  rules: any,
  label?: ReactNode
  hideStatus?: boolean
}

export const TextList = (props: TextListProps) => {
  return (
    <>
      <RelaxList
        name={props.type}
        hideStatus={props.hideStatus ? props.hideStatus : false}
        label={props.label}
        initialValuesSelector={GoogleSmartSettingsSelector}
        callAPI={UpdateGoogleSmartSettings}
        elementRules={
          [...props.rules, ruleNoDuplicates] || rulesetMinMax(1, 30)
        }
        rules={[...props.listRules, { noDuplicates: true }, ruleValidHeadline()]}
      >
        <Input />
      </RelaxList>
    </>
  );
};

//count min 6
//count max ?
//max length 30
export const Headlines = () => {
  const fulfilled = useSelectorWithParams(HasFilledHeadlinesSelector);
  return (
    <>
      <TextList
        type="headlines"
        listRules={rulesetArrayMinMax(6, 50)}
        rules={rulesetMinMax(1, 30)}
      />
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
};

//count min 3
//count max ?
//max length 60
export const ShortDescriptions = () => {
  const fulfilled = useSelectorWithParams(HasFilledHeadlinesSelector);

  return (
    <>
      <TextList
        type="short_descriptions"
        listRules={rulesetArrayMinMax(3, 50)}
        rules={rulesetMinMax(1, 60)}
      />
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
};

//count min 3
//count max ?
//max length 90
export const LongDescriptions = () => {
  const fulfilled = useSelectorWithParams(HasFilledHeadlinesSelector);
  return (
    <>
      <TextList
        type="descriptions"
        listRules={rulesetArrayMinMax(3, 50)}
        rules={rulesetMinMax(1, 90)}
      />
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
};
